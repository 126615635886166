import React from 'react'
import { Helmet } from 'react-helmet'

import '../styles/pages.scss'

let bucketAddress = `https://guanyenc-1253565450.cos.ap-beijing.myqcloud.com/illustrator`

const Ill = (i) => {
  let title = i.name
  if (i.replaceName !== undefined) {
    title = i.replaceName
  }

  let cls = 'ill'
  if (i.className !== undefined) {
    cls += ' ' + i.className
  }

  let st = { width: i.width }
  if (i.style !== undefined) {
    st = { ...st, ...i.style }
  }
  return (
    <div className={cls} style={st}>
      <img
        src={`${bucketAddress}/${i.name}.${i.fileType}`}
        alt={i.name}
        style={{ height: i.lineHeight, width: i.lineWidth }}
      />
      <span className='illName'>{title}</span>
    </div>
  )
}
Ill.defaultProps = {
  name: '',
  left: 0,
  width: '100%',
  lineHeight: 'auto',
  lineWidth: '100%',
  fileType: 'png',
  className: undefined,
  replaceName: undefined,
}

const RedScissorsGroup = () => {
  let group = [0, 1, 2, 3, 4, 5, 6, 7, 8]
  return (
    <div className='redScissorsGroup'>
      {group.map((i) => {
        return (
          <Ill
            key={i}
            style={{ width: 'auto' }}
            name={`红色剪刀${i}`}
            className='redScissors'
            lineHeight={242}
            lineWidth={257}
            replaceName=''
          />
        )
      })}
    </div>
  )
}

const Illustration = () => {
  return (
    <main>
      <Helmet>
        <title>Guanyenc Illustration</title>
      </Helmet>
      <div className='backgroundColor' />
      <div className='gallery'>
        <div className='pageTitle'>Illustration</div>
        <Ill name='虫虫在前 猫猫在后, 2020' lineHeight={75} />
        <Ill name='美食大作战, 2018' width={'51%'} lineHeight={120} />
        <Ill name='竖中指的蝴蝶, 2019' width={'47%'} lineHeight={120} />
        <Ill name='熊孩子, 2019' width={'49%'} lineHeight={230} />
        <Ill name='反向世界, 2019' width={'49%'} lineHeight={230} />

        <div style={{ width: '55%' }}>
          <Ill name='午后院子，2019' />
          <Ill name='Staff only-熊猫馆，2018' />
          <Ill name='Staff only-保龄球，2018' />
        </div>
        <div style={{ width: '42%' }}>
          <Ill name='粉红色星球，2019' lineHeight={560} />
          <Ill name='Hello!，2019' lineHeight={118} />
        </div>
        <Ill name='牙1' width={'33%'} replaceName="Tooth's story, 2018" />
        <Ill name='牙2' width={'33%'} replaceName='' />
        <Ill name='牙3' width={'33%'} replaceName='' />
        <Ill name='猪猪四季，2018' />

        {/* 特殊的红色剪刀，特殊处理 */}
        <div style={{ height: 270 }}>
          <RedScissorsGroup />
          <div className='illName'>红色剪刀，2019</div>
        </div>

        <Ill name='一个人的生活，2019' width={'40%'} lineHeight={125} />
        <Ill name='鸟浇人浇花，2018' width={'58%'} />
        <Ill name='隔离中的窥探，2020' />
        <Ill
          name='轮椅'
          width={'39%'}
          lineHeight={97}
          fileType='gif'
          replaceName='残疾人关爱, 2019'
        />
        <Ill name='公交车' width={'29%'} lineHeight={97} fileType='gif' replaceName='' />
        <Ill name='盲道' width={'30%'} lineHeight={97} fileType='gif' replaceName='' />

        <Ill name='猪猪幸运星，2018' fileType='gif' />
        <Ill name='偷星星，2020' />
        <Ill name='酝酿中的暴雨，2021' />
      </div>
    </main>
  )
}

export default Illustration
